<template>
    <b-modal
        :id="id"
        title="Import Data"
        centered
        no-close-on-backdrop
        @hidden="close"
    >
        <b-form-group label="CSV file">
            <b-form-file
                id="file-small"
                size="sm"
                accept=".csv"
                @change="loadTextFromFile"
            />
        </b-form-group>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="close"
                >
                    Close
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    :disabled="!file"
                    @click="importFile"
                >
                    Agree
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        id: {
            type: String,
            required: false,
            default: 'import-csv'
        }
    },

    data() {
        return {
            file: null,
            csvText: ''
        };
    },

    methods: {
        ...mapActions({
            importCsv: 'filesHelper/importCsv'
        }),

        async importFile() {
            const formData = new FormData();
            formData.append('file', this.file);

            this.$emit('import', formData);

            this.close();
        },

        close() {
            this.file = null;
            this.$bvModal.hide(this.id);
        },

        loadTextFromFile(ev) {
            const file = ev.target.files[0];
            this.file = file;
            const reader = new FileReader();

            reader.onload = e => (this.csvText = e.target.result);
            reader.readAsText(file);
        }
    }
};
</script>
