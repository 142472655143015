<template>
    <b-modal
        id="modalMedicalInformationCreateEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="$emit('hidden')"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="custom-modal-text text-left">
            <div>
                <div class="form-group">
                    <label for="title">Title</label>
                    <input
                        id="title"
                        v-model="data.title"
                        :class="{ 'is-invalid': $v.data.title.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Enter Title"
                    />
                </div>
                <div class="form-group">
                    <image-input
                        v-model="data.icon"
                        path-to-upload="/medical-informations/image"
                        path-to-get-image="/images/medical-informations"
                        label="Icon"
                        :error="$v.data.icon.$error"
                    />
                </div>
            </div>
            <h5 class="mb-3 text-uppercase"> Permissions: </h5>
            <div>
                <b-form-checkbox
                    v-model="allowDoctor"
                    class="my-2"
                    @change="updatePermissions"
                >
                    Doctor
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="allowNutrition"
                    class="my-2"
                    @change="updatePermissions"
                >
                    Nutrition
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="allowPharmacist"
                    class="my-2"
                    @change="updatePermissions"
                >
                    Pharmacist
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="allowNurse"
                    class="my-2"
                    @change="updatePermissions"
                >
                    Nurse
                </b-form-checkbox>
            </div>

            <subitems
                :subitems="data.subitems"
                @add-subitem="addSubitem"
                @update-subitem="updateSubitem"
                @delete-subitem="deleteSubitem"
            />
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitMedicalInformationDataHandler"
                >
                    {{ buttonText }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import 'flatpickr/dist/flatpickr.css';
import { required } from 'vuelidate/lib/validators';

import config from '@config';
import Subitems from './Subitems';
import ImageInput from '@components/elements/ImageInput';

export default {
    components: {
        ImageInput,
        Subitems
    },

    props: {
        updatingMedicalInformation: {
            type: Boolean,
            required: false,
            default: true
        },
        medicalInformation: {
            type: Object,
            required: false,
            default: null
        },
        modalTitle: {
            type: String,
            required: false,
            default: 'Add New Medical Information'
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Save'
        }
    },

    data() {
        return {
            data: {
                subitems: []
            },
            allowDoctor: false,
            allowPharmacist: false,
            allowNutrition: false,
            allowNurse: false
        };
    },

    computed: {
        imagePreviewUrl() {
            const { image } = this.data;

            return image
                ? `${config.mediaUrl}/images/medical-informations/${image.date}/${image.id}.${image.extension}`
                : '';
        }
    },

    validations: {
        data: {
            title: {
                required
            },
            icon: {
                required
            }
        }
    },

    methods: {
        hideModal() {
            this.$bvModal.hide('modalMedicalInformationCreateEdit');

            this.data = {
                subitems: []
            };
        },

        async openModalHandler() {
            await this.$nextTick();

            if (this.medicalInformation && this.updatingMedicalInformation) {
                this.data = { ...this.medicalInformation };

                this.allowDoctor = this.data.permissions?.includes('doctor');
                this.allowNutrition =
                    this.data.permissions?.includes('nutrition');
                this.allowPharmacist =
                    this.data.permissions?.includes('pharmacist');
                this.allowNurse = this.data.permissions?.includes('nurse');

                return;
            }

            this.data = {
                subitems: []
            };
        },

        submitMedicalInformationDataHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.$emit(
                this.updatingMedicalInformation
                    ? 'update-medical-information'
                    : 'create-medical-information',
                this.data
            );

            this.$v.$reset();
            this.hideModal();
        },

        addSubitem(subitem) {
            this.data.subitems.push(subitem);
        },

        updateSubitem(subitem) {
            const { index } = subitem;

            delete subitem.index;

            this.$set(this.data.subitems, index, subitem);

            this.submitMedicalInformationDataHandler();
        },

        deleteSubitem(index) {
            this.data.subitems.splice(index, 1);
        },

        updatePermissions() {
            const permissions = [];

            if (this.allowDoctor) {
                permissions.push('doctor');
            }

            if (this.allowPharmacist) {
                permissions.push('pharmacist');
            }

            if (this.allowNutrition) {
                permissions.push('nutrition');
            }

            if (this.allowNurse) {
                permissions.push('nurse');
            }

            this.data.permissions = permissions;
        }
    }
};
</script>

<style lang="scss" scoped>
.image-preview {
    width: 390px;

    img {
        width: 100%;
        aspect-ratio: 1.3;
        object-fit: cover;
    }
}
</style>
