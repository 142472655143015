<template>
    <div :class="mainClass">
        <page-header v-if="includeHeader" :title="title" :items="breadcrumbs" />

        <tabs
            v-if="tabsElements.length"
            :items="tabsElements"
            :active-item="activeTab"
            :actions="tabActions"
            is-card
            class="mb-3"
            @action="onTabAction"
            @item-change="activeTab = $event"
        />

        <awesome-table
            :key="tableKey"
            :labels="tableLabels"
            :items="items"
            :pagination="pagination"
            :filters="filters"
            :options="options"
            :is-loading="isLoading"
            :get-row-class="getRowClass"
            @row-click="onRowClick"
            @pagination-change="onPaginationChange"
            @add-button-click="onAddButtonClick"
            @filter-change="onFilterChange"
            @selection-change="onSelectionChange"
        >
            <template #globalActionButtons>
                <button
                    v-if="importPath"
                    v-b-modal.import-csv
                    class="btn btn-success mr-1 mb-1"
                >
                    <i class="fas fa-upload" />
                    Import
                </button>
                <button
                    v-for="(action, actionIndex) in globalActions"
                    :key="`global-action_${actionIndex}`"
                    class="btn btn-success mr-1 mb-1"
                    @click.stop="callAction(action)"
                >
                    <i :class="action.icon" />
                    {{ action.name }}
                </button>
            </template>
            <template #[`labels.actions`]="{ label }">
                <span style="color: gray">
                    {{ label }}
                </span>
            </template>
            <template #[`items.actions`]="{ item, index }">
                <button
                    v-for="(action, actionIndex) in tableActions"
                    v-show="isActionVisible(action, item)"
                    :key="`action_${actionIndex}`"
                    class="action-button"
                    @click.stop="callAction(action, item, index)"
                >
                    <i :class="action.icon" />
                </button>
            </template>
            <template #[`items.image`]="{ item }">
                <span v-if="item.image">
                    <img :src="getImagePath(item.image)" height="100" />
                </span>
            </template>
            <template #[`items.icon`]="{ item }">
                <span v-if="item.icon">
                    <img :src="getImagePath(item.icon)" height="100" />
                </span>
            </template>
        </awesome-table>

        <import-csv-modal
            v-if="importPath"
            :path="importPath"
            @import="onImport"
        />

        <component
            :is="item.name"
            v-for="item of additionalComponents"
            :key="item.name"
            v-bind="item.props || {}"
            v-on="item.events || {}"
        />
    </div>
</template>

<script>
import Tabs from '@components/elements/Tabs';
import ImportCsvModal from '@components/elements/ImportCsvModal';

export default {
    components: {
        Tabs,
        ImportCsvModal
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            mainClass: 'table-view',
            includeHeader: true,
            items: [],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            idToDelete: null,
            filters: JSON.parse(filters),
            isLoading: false,
            activeTab: {},
            pageTitle: 'Items',
            tableKey: 0
        };
    },

    computed: {
        title() {
            return this.pageTitle;
        },

        breadcrumbs() {
            return [
                {
                    text: 'NovoCare',
                    to: '/'
                },
                {
                    text: this.pageTitle,
                    active: true
                }
            ];
        },

        labels() {
            return [];
        },

        actions() {
            return [];
        },

        options() {
            return {};
        },

        tabsElements() {
            return [];
        },

        tabActions() {
            return [];
        },

        tableLabels() {
            const labels = [...this.labels];

            if (this.tableActions.length) {
                labels.push({
                    value: 'actions',
                    sortable: false,
                    width: '100px',
                    filterable: false
                });
            }

            return labels;
        },

        tableActions() {
            const actions = [...this.actions];

            if (this.includeDeleteAction) {
                actions.push({
                    icon: 'far fa-trash-alt',
                    method: 'showDeleteModal'
                });
            }

            return actions;
        },

        globalActions() {
            return [];
        },

        additionalFetchData() {
            return {};
        },

        additionalComponents() {
            return [];
        },

        importPath() {
            return false;
        },

        includeDeleteAction() {
            return true;
        }
    },

    watch: {
        activeTab() {
            this.resetPagination();
            this.fetchItems();
        }
    },

    created() {
        this.fetchItems();
    },

    methods: {
        getItems() {
            return {
                rows: [],
                count: 0
            };
        },
        deleteItem() {},
        onRowClick() {},
        onAddButtonClick() {},
        onTabAction() {},
        onImport() {},
        onSelectionChange() {},
        getImagePath() {},

        async fetchItems() {
            this.isLoading = true;

            try {
                const data = {
                    ...this.pagination,
                    filters: this.filters,
                    ...this.additionalFetchData
                };

                const { count, rows } = await this.getItems(data);

                this.items = rows;
                this.pagination.total = count;
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }

            this.isLoading = false;
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchItems();
        },

        onFilterChange(filters) {
            this.filters = { ...filters };

            this.fetchItems();
        },

        showDeleteModal(id) {
            this.idToDelete = id;
            this.$bvModal.show('confirm-modal');
        },

        callAction(action, id, index) {
            this[action.method](id, index, action.params);
        },

        async removeItem() {
            try {
                await this.deleteItem(this.idToDelete);

                this.items = this.items.filter(
                    item => item.id !== this.idToDelete
                );
                this.idToDelete = null;
                this.pagination.total--;

                this.$bvToast.toast('Item has been deleted.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (error) {
                console.error(error);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        isActionVisible(action, item) {
            const { where } = action;

            if (!where) {
                return true;
            }

            for (const key in where) {
                const condition = where[key];

                if (
                    (condition.negative && item[key]) ||
                    (!condition.negative && !item[key])
                ) {
                    return false;
                }
            }

            return true;
        },

        getRowClass() {
            return '';
        },

        resetPagination() {
            this.pagination.currentPage = 1;
            this.pagination.sortBy = 'created_at';
            this.pagination.search = '';
        }
    }
};
</script>
