<template>
    <div :class="{ card: isCard }">
        <div :class="{ 'card-body': isCard }">
            <ul class="nav nav-pills navtab-bg nav-justified">
                <li
                    v-for="(item, index) in items"
                    :key="index"
                    class="nav-item my-1"
                    :class="{ 'error-box': item.isError }"
                >
                    <a
                        href="#"
                        data-toggle="tab"
                        aria-expanded="false"
                        class="nav-link"
                        :class="{
                            active: activeItem[valueLabel] === item[valueLabel]
                        }"
                        @click.prevent="$emit('item-change', item)"
                    >
                        <span>{{ item[label] }}</span>
                    </a>
                </li>
            </ul>

            <ul v-if="actions.length" class="tabs-actions mt-4">
                <li
                    v-for="(action, index) in actions"
                    :key="index"
                    class="btn btn-success"
                >
                    <div @click="$emit('action', action.name)">
                        {{ action.text }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        activeItem: {
            type: Object,
            required: true
        },
        isCard: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: 'text'
        },
        valueLabel: {
            type: String,
            required: false,
            default: 'value'
        },
        actions: {
            type: Array,
            default: () => []
        }
    }
};
</script>
