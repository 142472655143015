// FOR USE THIS MIXIN PLEASE OVERWRITE this.dropzoneRelativePath IN VUE COMPONENT
import vue2Dropzone from 'vue2-dropzone';
import config from '@config';

export default {
    components: {
        vueDropzone: vue2Dropzone
    },

    computed: {
        dropzoneOptions() {
            const options = {
                url:
                    config.apiUrl +
                    this.dropzoneRelativePath,
                withCredentials: true,
                thumbnailWidth: 150,
                maxFilesize: 5,
                maxFiles: 1,
                acceptedFiles:
                    'image/jpg, image/png, image/jpeg, image/svg+xml',
                dictDefaultMessage:
                    "Upload New Image <i class='fe-upload'></i>",
                addRemoveLinks: true
            };

            return options;
        },

        multiDropzoneOptions() {
            const options = {
                url:
                    config.apiUrl +
                    this.dropzoneRelativePath,
                thumbnailWidth: 150,
                maxFilesize: 5,
                acceptedFiles:
                    'image/jpg, image/png, image/jpeg, image/svg+xml',
                dictDefaultMessage:
                    "Upload New Image <i class='fe-upload'></i>",
                addRemoveLinks: true,
                duplicateCheck: true,
                withCredentials: true
            };

            return options;
        }
    }
};
