<script>
import { mapActions } from 'vuex';
import config from '@config';
import TableView from '@components/elements/TableView';
import ConfirmModal from '@components/elements/ConfirmModal';
import ModalMedicalInformationCreateEdit from '@components/medical-informations/ModalMedicalInformationCreateEdit';

export default {
    page: {
        title: 'Medical Information'
    },

    components: {
        ModalMedicalInformationCreateEdit,
        ConfirmModal
    },

    extends: TableView,

    data() {
        return {
            pageTitle: 'Medical Information',
            medicalInformationToUpdate: null,
            medicalInformationToDelete: null,
            modalTitle: ''
        };
    },

    computed: {
        additionalComponents() {
            return [
                {
                    name: 'modal-medical-information-create-edit',
                    props: {
                        modalTitle: this.medicalInformationToUpdate
                            ? 'Edit Medical Information'
                            : 'Add Medical Information',
                        buttonText: this.medicalInformationToUpdate
                            ? 'Update'
                            : 'Add',
                        updatingMedicalInformation:
                            !!this.medicalInformationToUpdate,
                        medicalInformation: this.medicalInformationToUpdate
                    },
                    events: {
                        hidden: this.hideModal,
                        'create-medical-information':
                            this.createMedicalInformationHandler,
                        'update-medical-information':
                            this.updateMedicalInformationHandler
                    }
                },
                {
                    name: 'confirm-modal',
                    props: {
                        title: this.modalTitle
                    },
                    events: {
                        agree: this.deleteMedicalInformationHandler,
                        disagree: this.hideModal
                    }
                }
            ];
        },

        labels() {
            return [
                { value: 'icon', width: '200px' },
                { value: 'title', width: '180px' },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                },
                {
                    value: 'updated_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                }
            ];
        },

        actions() {
            const actions = [
                {
                    icon: 'fas fa-pen mr-1',
                    method: 'showEditModal'
                }
            ];

            return actions;
        },

        options() {
            return {
                searchInput: false,
                clickableRows: false,
                sort: false,
                filters: false
            };
        }
    },

    methods: {
        ...mapActions({
            getItems: 'medicalInformations/index',
            createMedicalInformation: 'medicalInformations/store',
            updateMedicalInformation: 'medicalInformations/update',
            deleteMedicalInformation: 'medicalInformations/destroy'
        }),

        async createMedicalInformationHandler(data) {
            try {
                this.$bvToast.toast(
                    'The medical information is being added, please wait.',
                    {
                        title: 'Success!',
                        variant: 'success',
                        solid: true
                    }
                );

                const response = await this.createMedicalInformation(data);

                if (!this.items.map(item => item.id).includes(response.id)) {
                    this.items = [response, ...this.items];
                }

                this.$bvToast.toast('Medical information has been added.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async updateMedicalInformationHandler(data) {
            try {
                const updatedMedicalInformation =
                    await this.updateMedicalInformation(data);

                const index = this.items.findIndex(
                    item => item.id === updatedMedicalInformation.id
                );

                this.items[index] = updatedMedicalInformation;

                this.$bvToast.toast('Medical information has been updated.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async deleteMedicalInformationHandler() {
            try {
                await this.deleteMedicalInformation(
                    this.medicalInformationToDelete
                );

                this.items = this.items.filter(
                    item => item.id !== this.medicalInformationToDelete.id
                );

                this.$bvToast.toast('Medical information has been deleted.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        showEditModal(medicalInformation) {
            this.medicalInformationToUpdate = medicalInformation;

            this.$bvModal.show('modalMedicalInformationCreateEdit');
        },

        showDeleteModal(medicalInformation) {
            this.medicalInformationToDelete = medicalInformation;
            this.modalTitle =
                'Please confirm that you want to delete this medical information';

            this.$bvModal.show('confirmModal');
        },

        hideModal() {
            this.medicalInformationToUpdate = null;
            this.medicalInformationToDelete = null;
        },

        onAddButtonClick() {
            this.$bvModal.show('modalMedicalInformationCreateEdit');
        },

        getImagePath(image) {
            return `${config.mediaUrl}/images/medical-informations/${image.date}/${image.id}.${image.extension}`;
        }
    }
};
</script>
