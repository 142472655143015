var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.mainClass},[(_vm.includeHeader)?_c('page-header',{attrs:{"title":_vm.title,"items":_vm.breadcrumbs}}):_vm._e(),(_vm.tabsElements.length)?_c('tabs',{staticClass:"mb-3",attrs:{"items":_vm.tabsElements,"active-item":_vm.activeTab,"actions":_vm.tabActions,"is-card":""},on:{"action":_vm.onTabAction,"item-change":function($event){_vm.activeTab = $event}}}):_vm._e(),_c('awesome-table',{key:_vm.tableKey,attrs:{"labels":_vm.tableLabels,"items":_vm.items,"pagination":_vm.pagination,"filters":_vm.filters,"options":_vm.options,"is-loading":_vm.isLoading,"get-row-class":_vm.getRowClass},on:{"row-click":_vm.onRowClick,"pagination-change":_vm.onPaginationChange,"add-button-click":_vm.onAddButtonClick,"filter-change":_vm.onFilterChange,"selection-change":_vm.onSelectionChange},scopedSlots:_vm._u([{key:"globalActionButtons",fn:function(){return [(_vm.importPath)?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.import-csv",modifiers:{"import-csv":true}}],staticClass:"btn btn-success mr-1 mb-1"},[_c('i',{staticClass:"fas fa-upload"}),_vm._v(" Import ")]):_vm._e(),_vm._l((_vm.globalActions),function(action,actionIndex){return _c('button',{key:("global-action_" + actionIndex),staticClass:"btn btn-success mr-1 mb-1",on:{"click":function($event){$event.stopPropagation();return _vm.callAction(action)}}},[_c('i',{class:action.icon}),_vm._v(" "+_vm._s(action.name)+" ")])})]},proxy:true},{key:"labels.actions",fn:function(ref){
var label = ref.label;
return [_c('span',{staticStyle:{"color":"gray"}},[_vm._v(" "+_vm._s(label)+" ")])]}},{key:"items.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _vm._l((_vm.tableActions),function(action,actionIndex){return _c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActionVisible(action, item)),expression:"isActionVisible(action, item)"}],key:("action_" + actionIndex),staticClass:"action-button",on:{"click":function($event){$event.stopPropagation();return _vm.callAction(action, item, index)}}},[_c('i',{class:action.icon})])})}},{key:"items.image",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('span',[_c('img',{attrs:{"src":_vm.getImagePath(item.image),"height":"100"}})]):_vm._e()]}},{key:"items.icon",fn:function(ref){
var item = ref.item;
return [(item.icon)?_c('span',[_c('img',{attrs:{"src":_vm.getImagePath(item.icon),"height":"100"}})]):_vm._e()]}}],null,true)}),(_vm.importPath)?_c('import-csv-modal',{attrs:{"path":_vm.importPath},on:{"import":_vm.onImport}}):_vm._e(),_vm._l((_vm.additionalComponents),function(item){return _c(item.name,_vm._g(_vm._b({key:item.name,tag:"component"},'component',item.props || {},false),item.events || {}))})],2)}
var staticRenderFns = []

export { render, staticRenderFns }