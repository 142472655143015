<template>
    <div>
        <label :for="customRef">{{ label }}</label>
        <vue-dropzone
            :id="customRef"
            :ref="customRef"
            :options="dropzoneOptions"
            class="d-flex justify-content-center align-items-center"
            :style="{
                border: error ? '1px solid red' : '',
                color: error ? 'red' : ''
            }"
            @vdropzone-complete="changeImage"
            @vdropzone-removed-file="onImageRemoved"
        />
    </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import config from '@config';

export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    props: {
        pathToUpload: {
            type: String,
            required: true,
            default: '/images'
        },
        pathToGetImage: {
            type: String,
            required: true,
            default: '/images'
        },
        value: {
            type: Object,
            required: false,
            default: () => {}
        },
        label: {
            type: String,
            required: false,
            default: 'Image'
        },
        type: {
            type: String,
            required: false,
            default: 'single'
        },
        options: {
            type: Object,
            required: false,
            default: () => {}
        },
        refProps: {
            type: String,
            required: false,
            default: ''
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        dropzoneOptions() {
            if (this.type === 'single') {
                const options = {
                    url: config.apiUrl + this.pathToUpload,
                    withCredentials: true,
                    thumbnailWidth: 150,
                    maxFilesize: 5,
                    maxFiles: 1,
                    acceptedFiles:
                        'image/jpg, image/png, image/jpeg, image/svg+xml',
                    dictDefaultMessage:
                        "Upload New Image <i class='fe-upload'/>",
                    addRemoveLinks: true
                };
                return { ...options, ...this.options };
            }
            const options = {
                url: config.apiUrl + this.pathToUpload,
                thumbnailWidth: 150,
                maxFilesize: 5,
                acceptedFiles:
                    'image/jpg, image/png, image/jpeg, image/svg+xml',
                dictDefaultMessage: "Upload New Image <i class='fe-upload'/>",
                addRemoveLinks: true,
                duplicateCheck: true,
                withCredentials: true
            };
            return { ...options, ...this.options };
        },
        customRef() {
            if (this.refProps) {
                return this.refProps;
            }
            return (
                Math.random()
                    .toString(36)
                    .substring(2, 30)
                    .match(/[a-zA-Z]+/g)
                    .join('') + 'ImageDropzone'
            );
        }
    },
    async mounted() {
        await this.$nextTick();
        await this.$nextTick();

        const image = { ...this.value };

        if (image.id) {
            await this.$nextTick();

            const imageUrl = `${config.mediaUrl}${this.pathToGetImage}/${image.date}/${image.id}.${image.extension}`;

            const backgroundImage = {
                size: image.size.height * image.size.width,
                name: 'Image',
                type: 'image/png'
            };

            this.$refs[this.customRef].manuallyAddFile(
                backgroundImage,
                imageUrl
            );
        }
    },
    methods: {
        changeImage(e) {
            const ref = this.$refs[this.customRef];
            if (ref.dropzone.files.length > 1) {
                ref.dropzone.removeFile(ref.dropzone.files[0]);
            }
            if (!e.xhr) {
                return;
            }
            const response = JSON.parse(e.xhr.response);
            this.$emit('input', response);
        },

        onImageRemoved() {
            if (this.type === 'single') {
                this.$emit('input', {});
            }
        }
    }
};
</script>
