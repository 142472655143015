<template>
    <div class="table-responsive my-5">
        <h5 class="mb-3 text-uppercase"> Articles and Conferences: </h5>
        <div class="form-group col-12">
            <label for="job_type">Type</label>
            <b-form-select
                v-model="newSubitem.type"
                :class="{ 'is-invalid': $v.newSubitem.type.$error }"
                :options="types"
                text-field="text"
            />
        </div>
        <div class="form-group col-12">
            <label for="job_type">Section</label>
            <b-form-select
                v-model="newSubitem.section"
                :class="{ 'is-invalid': $v.newSubitem.section.$error }"
                :options="sections"
                text-field="text"
            />
        </div>
        <div class="form-group col-12">
            <image-input
                v-model="newSubitem.image"
                path-to-upload="/medical-informations/image"
                path-to-get-image="/images/medical-informations"
                label="Image"
                :error="$v.newSubitem.image.$error"
            />
        </div>
        <div class="form-group col-12">
            <input
                v-model="newSubitem.title"
                class="form-control"
                placeholder="Title"
                :class="{
                    'is-invalid': $v.newSubitem.title.$error
                }"
            />
        </div>
        <div class="form-group col-12">
            <input
                v-model="newSubitem.date"
                class="form-control"
                placeholder="Date"
            />
        </div>
        <div v-if="newSubitem.type === 'pdf'" class="form-group col-12">
            <input
                v-model="newSubitem.timeToRead"
                class="form-control"
                placeholder="Time to read"
            />
        </div>
        <div v-else-if="newSubitem.type === 'video'" class="form-group col-12">
            <input
                v-model="newSubitem.location"
                class="form-control"
                placeholder="Location"
            />
        </div>
        <div class="form-group col-12">
            <b-form-select
                v-model="newSubitem.label"
                :options="labels"
                text-field="name"
                value-field="name"
                class="mb-2 form-control"
            />
        </div>
        <div v-if="newSubitem.type === 'pdf'">
            <div class="form-group col-12">
                <label for="pdfDropzone">PDF File</label>
                <vue-dropzone
                    id="pdfDropzone"
                    ref="pdfDropzone"
                    :options="pdfDropzoneOptions"
                    class="h-100 d-flex justify-content-center align-items-center"
                    @vdropzone-complete="uploadPDF($event)"
                />
            </div>
            <div class="form-group col-12">
                <label for="pdfLink">PDF Link</label>
                <input
                    id="pdfLink"
                    v-model="newSubitem.url"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': $v.newSubitem.url.$error
                    }"
                />
            </div>
        </div>
        <div v-else-if="newSubitem.type === 'video'">
            <div class="form-group col-12">
                <label for="videoLink">Video Link</label>
                <input
                    id="videoLink"
                    v-model="newSubitem.url"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': $v.newSubitem.url.$error
                    }"
                />
            </div>
        </div>

        <div class="form-group col-12 col-lg-6">
            <button
                class="btn btn-danger waves-effect waves-light"
                data-animation="fadein"
                data-plugin="custommodal"
                data-overlaycolor="#38414a"
                @click="addHandler"
            >
                <i class="mdi mdi-plus-circle mr-1" />
                Add
            </button>
        </div>
        <table
            id="subitems-datatable"
            class="table table-centered table-striped text-center"
        >
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="subitem in subitemsToDisplay" :key="subitem.index">
                    <td>{{ subitem.title }}</td>
                    <td>{{ subitem.type }}</td>
                    <td @click.stop>
                        <a
                            href="#"
                            class="action-icon"
                            @click.prevent="showSubitemEditModal(subitem)"
                        >
                            <i class="fas fa-pen mr-1" />
                        </a>
                        <a
                            href="#"
                            class="action-icon"
                            @click.prevent="showSubitemDeleteModal(subitem)"
                        >
                            <i class="far fa-trash-alt" />
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>

        <modal-subitem-edit
            :subitem="subitemToUpdate"
            @hidden="hiddenModalSubitemEditHandler"
            @update-subitem="updateSubitemHandler"
        />

        <confirm-modal
            id="confirmSubitemDeleteModal"
            title="Do you want to delete this item?"
            @agree="deleteSubitemHandler"
            @disagree="indexToDelete = null"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import config from '@config';
import ModalSubitemEdit from './ModalSubitemEdit';
import ImageInput from '@components/elements/ImageInput';
import ConfirmModal from '@components/elements/ConfirmModal';
import DropzoneMixin from '@components/mixins/dropzone.mixin';

export default {
    components: {
        ImageInput,
        ConfirmModal,
        ModalSubitemEdit
    },

    mixins: [DropzoneMixin],

    props: {
        subitems: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    validations: {
        newSubitem: {
            title: {
                required
            },
            type: {
                required
            },
            section: {
                required
            },
            image: {
                required
            },
            url: {
                required
            }
        }
    },

    data() {
        return {
            indexToDelete: null,
            subitemToUpdate: null,
            newSubitem: {},
            types: [
                { value: 'pdf', text: 'pdf' },
                { value: 'video', text: 'video' }
            ],
            sections: [
                { value: 'conferences_info', text: 'Conferences information' },
                { value: 'more_info', text: 'More information' }
            ],
            labels: []
        };
    },

    async mounted() {
        this.labels = await this.getLabels();
        this.labels = [{ name: '' }, ...this.labels];

        this.newSubitem.label = this.labels[0].name;
    },

    computed: {
        subitemsToDisplay() {
            return this.subitems?.map((subitem, index) => ({
                ...subitem,
                index
            }));
        },

        pdfDropzoneOptions() {
            const options = {
                url: config.apiUrl + '/products/pdf/store',
                withCredentials: true,
                thumbnailWidth: 150,
                maxFilesize: 300,
                maxFiles: 1,
                acceptedFiles: '.pdf',
                dictDefaultMessage: "Upload New PDF <i class='fe-upload'></i>",
                addRemoveLinks: true
            };

            return options;
        }
    },

    methods: {
        ...mapActions({
            getLabels: 'labels/index'
        }),

        addHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.$emit('add-subitem', this.newSubitem);

            this.newSubitem = {};

            this.$v.$reset();
        },

        uploadPDF(e) {
            const ref = this.$refs.pdfDropzone;

            if (ref.dropzone.files.length > 1) {
                ref.dropzone.removeFile(ref.dropzone.files[0]);
            }

            if (!e.xhr) {
                return;
            }

            this.newSubitem.url = e.xhr.response;
        },

        showSubitemDeleteModal(subitem) {
            this.indexToDelete = subitem.index;

            this.$bvModal.show('confirmSubitemDeleteModal');
        },

        showSubitemEditModal(subitem) {
            this.subitemToUpdate = subitem;

            this.$bvModal.show('modalSubitemEdit');
        },

        deleteSubitemHandler() {
            this.$emit('delete-subitem', this.indexToDelete);
        },

        hiddenModalSubitemEditHandler() {
            this.subitemToUpdate = null;
        },

        updateSubitemHandler(data) {
            this.$emit('update-subitem', data);
        }
    }
};
</script>
