<template>
    <b-modal
        id="modalSubitemEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="hideModal"
    >
        <template slot="modal-title"> Edit Article / Conference </template>
        <div class="custom-modal-text text-left">
            <div class="form-group col-12">
                <label for="job_type">Type</label>
                <b-form-select
                    v-model="data.type"
                    :class="{ 'is-invalid': $v.data.type.$error }"
                    :options="types"
                    text-field="text"
                />
            </div>
            <div class="form-group col-12">
                <label for="job_type">Section</label>
                <b-form-select
                    v-model="data.section"
                    :class="{ 'is-invalid': $v.data.section.$error }"
                    :options="sections"
                    text-field="text"
                />
            </div>
            <div class="form-group col-12">
                <image-input
                    v-model="data.image"
                    path-to-upload="/medical-informations/image"
                    path-to-get-image="/images/medical-informations"
                    label="Image"
                    :error="$v.data.image.$error"
                />
            </div>
            <div class="form-group col-12">
                <input
                    v-model="data.title"
                    class="form-control"
                    placeholder="Title"
                    :class="{
                        'is-invalid': $v.data.title.$error
                    }"
                />
            </div>
            <div class="form-group col-12">
                <input
                    v-model="data.date"
                    class="form-control"
                    placeholder="Date"
                />
            </div>
            <div v-if="data.type === 'pdf'" class="form-group col-12">
                <input
                    v-model="data.timeToRead"
                    class="form-control"
                    placeholder="Time to read"
                />
            </div>
            <div v-else-if="data.type === 'video'" class="form-group col-12">
                <input
                    v-model="data.location"
                    class="form-control"
                    placeholder="Location"
                />
            </div>
            <div class="form-group col-12">
                <b-form-select
                    v-model="data.label"
                    :options="labels"
                    text-field="name"
                    value-field="name"
                    class="mb-2 form-control"
                />
            </div>
            <div v-if="data.type === 'pdf'">
                <div class="form-group col-12">
                    <label for="pdfDropzone">PDF File</label>
                    <vue-dropzone
                        id="pdfDropzone"
                        ref="pdfDropzone"
                        :options="pdfDropzoneOptions"
                        class="h-100 d-flex justify-content-center align-items-center"
                        @vdropzone-complete="uploadPDF($event)"
                    />
                </div>
                <div class="form-group col-12">
                    <label for="pdfLink">PDF Link</label>
                    <input
                        id="pdfLink"
                        v-model="data.url"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': $v.data.url.$error
                        }"
                    />
                </div>
            </div>
            <div v-else-if="data.type === 'video'">
                <div class="form-group col-12">
                    <label for="videoLink">Video Link</label>
                    <input
                        id="videoLink"
                        v-model="data.url"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': $v.data.url.$error
                        }"
                    />
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitSubitemDataHandler"
                >
                    Save
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import config from '@config';
import ImageInput from '@components/elements/ImageInput';
import DropzoneMixin from '@components/mixins/dropzone.mixin';

export default {
    components: {
        ImageInput
    },

    mixins: [DropzoneMixin],

    props: {
        subitem: {
            type: Object,
            required: false,
            default: null
        }
    },

    validations: {
        data: {
            title: {
                required
            },
            image: {
                required
            },
            type: {
                required
            },
            section: {
                required
            },
            url: {
                required
            }
        }
    },

    data() {
        return {
            data: {},
            types: [
                { value: 'pdf', text: 'pdf' },
                { value: 'video', text: 'video' }
            ],
            sections: [
                { value: 'conferences_info', text: 'Conferences information' },
                { value: 'more_info', text: 'More information' }
            ],
            labels: []
        };
    },

    async mounted() {
        this.labels = await this.getLabels();
        this.labels = [{ name: '' }, ...this.labels];

        this.data.label = this.labels[0].name;
    },

    computed: {
        pdfDropzoneOptions() {
            const options = {
                url: config.apiUrl + '/products/pdf/store',
                withCredentials: true,
                thumbnailWidth: 150,
                maxFilesize: 300,
                maxFiles: 1,
                acceptedFiles: '.pdf',
                dictDefaultMessage: "Upload New PDF <i class='fe-upload'></i>",
                addRemoveLinks: true
            };

            return options;
        }
    },

    methods: {
        ...mapActions({
            getLabels: 'labels/index'
        }),

        hideModal() {
            this.$bvModal.hide('modalSubitemEdit');
            this.$emit('hidden');
        },

        async openModalHandler() {
            await this.$nextTick();

            if (this.subitem) {
                return (this.data = { ...this.subitem });
            }

            this.data = {};
        },

        submitSubitemDataHandler() {
            this.$emit('update-subitem', this.data);
        },

        uploadPDF(e) {
            const ref = this.$refs.pdfDropzone;

            if (ref.dropzone.files.length > 1) {
                ref.dropzone.removeFile(ref.dropzone.files[0]);
            }

            if (!e.xhr) {
                return;
            }

            this.data.url = e.xhr.response;
        }
    }
};
</script>
